.sideBarLink:hover {
    background-color: #d3e3dc !important;
}

.sideBarLink {
    padding-left: 22px !important;
}

.sideBarLinkActive {
    background-color: #d3e3dc !important;
    border-color: #7ca493 !important;
    border-left-style: solid !important;
    border-width: 4px !important;
}

.drawerButtonCont {
    background-color: #ebf1f0 !important;
}

.userItem {
    background-color: #b7d3c8;
}

.list {
    padding-top: 0px !important;
}

.userName {
    color: white;
}

.toolbar {
    box-shadow: none !important;
}

.bottomCont {
    height: 100%;
}

.logo {
    width: 30px;
    margin-bottom: 10px;
}

.logoText {
    width: 100px;
}

.text {
    color: #7e9cb5 !important;
}

.version {
    margin-bottom: 10px;
}

.link {
    color: grey;
}

.menuButton {
    color: white !important;
}

.menuText {
    color: grey !important;
}

.toolbarContent {
    color: white !important;
}

.goBackButton {
    color: white !important;
    margin-left: 30px !important;
}

.goBackButtonClosed {
    margin-left: 75px !important;
}