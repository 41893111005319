.mediaContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.imgPreview {
  width: 120px;
  height: 120px;
  resize: vertical;
  border-radius: 8px;
}

.pdfLink {
  background-color: rgb(211, 67, 67);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
}

.pdfLinkText {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  color: white;
}