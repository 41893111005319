.centered_content_row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
  margin-top: 10px;
}

.dropbox {
  background: #e0e0e0;
  color: dimgray;
  padding: 16px 16px;
  box-sizing: border-box;
  height: 80px;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.file_input {
  opacity: 0;
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
}

.dropbox:hover {
  background: #bdbdbd;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  margin: 0;
}

.img_preview {
  height: 60px;
  resize: horizontal;
}

.preview_container {
  display: flex;
  flex-direction: column;
}

.pdf_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fb8c00;
  font-weight: bold;
  height: 50px;
  width: 50px;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid #fb8c00;
  cursor: default;
}

.remove_btn {
  align-self: flex-end;
  transform: translate(13px, -3px);
}
