.tableRowExpanded {
    margin: 0px !important;
}

.tableRow:hover {
    background-color: #e5eeea;
}

.tableHeader {
    padding-left: 10px;
}

.header {
    padding: 10px;
}

.progressCont {
    height: 40px !important;
}

.noAttText {
    color: grey;
}

.noAttCont {
    width: 100% !important;
}