.loginCard {
	width: 70%;
	max-width: 70% !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin-top: 25px;
}

.buttonCont {
	margin: 10px;
}

.restoreButton {
	color: white !important;
	margin-left: 10px !important;
	width: 200px;
}

.infoText {
	color: grey;
}
