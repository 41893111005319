.badge {
    color: white;
}

.tableRow:hover {
    background-color: #e5eeea;
}

.noAttText {
    color: grey;
}

.noAttCont {
    height: 30px !important;
    width: 100% !important;
}