.itemLeft {
    flex-basis: 30%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    align-self: center;
    color: grey;
}

.itemRight {
    flex-basis: 65%;
    font-weight: 300;
    font-size: 14px;
    /*font-style: normal;*/
    align-self: center;
    line-height: 36px;
}