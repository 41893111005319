.iconLogin {
	position: absolute;
	z-index: 2;
	top: -28px;
	font-size: 50px;
}

.imgOverlaySide {
	background-image: url(./../../assets/Poster-Vertical-Tramon.jpg);
	background-size: cover;
	background-position: center;
	z-index: -1;
	height: 100vh;
	width: 30%;
	justify-content: space-between;
	width: 30%;
}

.imgOverlaySide:after {
	content: "\A";
	position: absolute;
	width: 30%; /* Hay que solucionar esto */
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	opacity: 0.8;
}

.tenantContainer {
	height: 50%;
	margin-bottom: 100px;
}

.tenantContainerMobile {
	height: 15%;
	/* margin-bottom: 20px; */
}

.tenantLogo {
	opacity: 1;
	z-index: 1;
	width: 150px;
	height: auto;
	max-height: 75%;
}

.tenantLogoMobile{
	opacity: 1;
	z-index: 1;
	width: 125px;
	height: auto;
}

.bottomContainer {
	margin: 20px;
}

.simfinixLogo {
	opacity: 1;
	z-index: 1;
	width: 120px;
	height: auto;
}

.poweredString {
	color: white;
	/* margin: 15px; */
	opacity: 1;
}

.poweredStringMobile {
	color: grey;
	/* margin: 15px; */
	opacity: 1;
}

.loginCard {
	width: 70%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin-top: 25px;
}

.formInput {
	font-size: 5px;
}

.logo-mobile {
	width: 270px;
 }

.restoreTitle {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
}

.textSecondary {
	color: #9e9e9e!important;
}

.restoreButton {
	color: white !important;
	margin-left: 10px !important;
}

.buttonCont {
	margin: 10px;
}
