.evolutionContainer {
    padding-top: 18px;
    padding-bottom: 18px;
    border-top: 1px solid #e5eeea;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}