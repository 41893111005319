.tableRow:hover {
    background-color: #e5eeea;
}

.tableRow {
    text-decoration: none;
}

.classContent {
    box-shadow: 1px 1px 1px black;
}

.backdrop {
    z-index: 3;
    color: '#fff';
}